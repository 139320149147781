<template>
  <div class="card card-statistics">
    <div class="card-body">
      <h5 class="card-title text-warning mb-1">
        Team Members
      </h5>
      <p class="card-text">
        Merchant team members and create new team member
      </p>
      <a class="btn btn-lg btn-primary bg-primary bg-lighten-3 py-1 d-flex flex-row justify-content-center" @click="onClickCreatePopup()">
        <p-icon size="36px" name="bi-people-fill" class="align-self-center mr-2" />
        <span class="align-self-center">
          Team Members
        </span>
      </a>
    </div>
    <div>
      <dx-util-popup
        ref="popupTeamMemberRef"
        :show-close-button="true"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-title="true"
        :title="teamMemberPopupTitle"
        :full-screen="false"
        content-template="popup-content"
        @shown="onShownTeamMemberPopup"
        @hidden="onHiddenTeamMemberPopup"
      >
        <dx-util-position at="center" my="center" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
        <template #popup-content>
          <dx-util-scroll-view width="100%" height="100%">
            <div class="row">
              <div class="col-12 col-lg-4">
                  <dx-data-grid
                    id="teamMemberGrid"
                    ref="teamMemberGridRef"
                    :data-source="TeamMemberDataSource"
                    :allow-column-reordering="true"
                    :allow-column-resizing="true"
                    :column-auto-width="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :show-borders="true"
                    :row-alternation-enabled="false"
                    :hover-state-enabled="true"
                    :customize-columns="customizeColumns"
                    @option-changed="onOptionChanged"
                    @initialized="onInitialized"
                    @content-ready="onContentReady"
                  >
                    <dx-column data-field="name" />
                    <dx-column data-field="email" />
                  </dx-data-grid>
              </div>
              <div class="col-12 col-lg-8">
                <dx-util-select-box
                  :data-source="companyUsers"
                  display-expr="fullName"
                  label-mode="floating"
                  label="Company User"
                  :show-clear-button="true"
                  @value-changed="onUserValueChangedEvent"
                />
                <dx-util-form ref="memberFormRef" :form-data="memberFormData" :col-count="1" :show-colon-after-label="true" label-mode="floating" label-location="left">
                  <dx-util-group-item :col-count="1" caption="Create New User" css-class="border rounded px-1 mt-1">
                    <dx-util-simple-item data-field="name" :editor-options="inputAttrs">
                      <dx-util-required-rule message="Name is required" />
                    </dx-util-simple-item>
                    <dx-util-simple-item data-field="email" :editor-options="inputAttrs">
                      <dx-util-required-rule message="Email is required" />
                    </dx-util-simple-item>
                    <dx-util-simple-item data-field="team_role" editor-type="dxSelectBox" :editor-options="teamRoleOptions">
                      <dx-util-required-rule message="Team Role is required" />
                    </dx-util-simple-item>
                    <dx-util-simple-item :editor-options="passwordOptions" data-field="password">
                      <dx-util-required-rule message="Password is required" />
                    </dx-util-simple-item>
                    <dx-util-simple-item :editor-options="passwordOptions" data-field="password_confirmation">
                      <dx-util-required-rule message="Confirm Password is required" />
                      <dx-util-compare-rule :comparison-target="passwordComparison" message="Password and Confirm Password do not match" />
                    </dx-util-simple-item>
                  </dx-util-group-item>
                  <dx-util-group-item :col-count="1">
                    <dx-util-button-item :button-options="saveButtonOptions" horizontal-alignment="right" />
                  </dx-util-group-item>
                </dx-util-form>
              </div>
            </div>
          </dx-util-scroll-view>
        </template>
      </dx-util-popup>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import GridBase from '@core/dev-extreme/mixins/grid/base'
import companyService from '@/http/requests/company/companyService'
import { Notify } from '@robustshell/utils/index'
import useCurrentUser from '@/libs/app/current-user'
import staxService from '@/http/requests/external/stax'
import { TeamMemberDataSource } from './team-member.store'
import teamMemberRoleEnum, { getListTeamMemberRoleEnum } from './team-member-roles.enum'

export default {
  mixins: [GridBase],
  setup() {
    const {
      userCompanyId,
    } = useCurrentUser()
    return {
      userCompanyId,
    }
  },
  data() {
    return {
      TeamMemberDataSource,
      teamMemberPopupTitle: '',
      defaultMemberFormData: {},
      memberFormData: {
        user_id: '',
        name: '',
        email: '',
        team_role: '',
        team_enabled: true,
        password: '',
        password_confirmation: '',
        send_verification_email: false,
        url: 'https://payment.prepshiphub.com/#/verify',
      },
      companyUsers: [],
      inputAttrs: {
        readOnly: true,
        autocomplete: 'off',
      },
      inputAutoAttrs: {
        autocomplete: 'off',
      },
    }
  },
  computed: {
    popupIns() {
      const popup = this.$refs.popupTeamMemberRef.instance
      return popup
    },
    cancelButtonOptions() {
      return {
        text: 'Close',
        type: 'danger',
        onClick: () => {
          this.onCloseTeamMemberPopup()
        },
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e.event)
        },
      }
    },
    passwordOptions() {
      return {
        mode: 'password',
        inputAttrs: {
          autocomplete: 'off',
        },
      }
    },
    teamRoleOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        valueExpr: 'value',
        displayExpr: 'text',
        items: getListTeamMemberRoleEnum(),
        acceptCustomValue: false,
        inputAttrs: {
          autocomplete: 'off',
        },
      }
    },
  },
  mounted() {
    Object.assign(this.defaultMemberFormData, this.memberFormData)
  },
  methods: {
    passwordComparison() {
      return this.memberFormData.password
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        TeamMemberDataSource.pageIndex(0)
        TeamMemberDataSource.reload()
      }
    },
    onClickCreatePopup() {
      this.popupIns.show()
    },
    onShownTeamMemberPopup() {
      this.getUsers()
      this.teamMemberPopupTitle = 'Team Members'
    },
    onCloseTeamMemberPopup() {
      this.popupIns.hide()
    },
    onHiddenTeamMemberPopup() {
      this.teamMemberPopupTitle = ''
      Object.assign(this.memberFormData, this.defaultMemberFormData)
    },
    async getUsers() {
      this.companyUsers.length = 0
      const users = await companyService.fetchUsersByCompanyId(this.userCompanyId, 'page=0&size=100')
      users.data.content.forEach(item => {
        this.companyUsers.push({
          email: item.email,
          fullName: item.fullName,
          id: item.id,
        })
      })
    },
    onUserValueChangedEvent(e) {
      this.memberFormData.user_id = e.value.id
      this.memberFormData.name = e.value.fullName
      this.memberFormData.email = e.value.email
    },
    async handleSubmit() {
      const form = this.$refs.memberFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        try {
          await staxService.createTeamMember(this.memberFormData)
          TeamMemberDataSource.reload()
          Object.assign(this.memberFormData, this.defaultMemberFormData)
          Notify.success('Team member has been added successfully.')
        } catch (error) {
          Notify.danger(error.message)
        }
      }
    },
  },
}
</script>

<style>

</style>
