import find from 'lodash/find'

const staxSignupUrl = process.env.VUE_APP_STAX_SIGNUP_URL

/**
 * Enum for PSH Stax Underwriting Substatus Enum.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const underwritingSubStatusEnum = Object.freeze({
  REJECTED_EXPIRED: {
    key: 'REJECTED_EXPIRED',
    value: 'REJECTED_EXPIRED',
    text: 'Expired',
    message: 'The application has expired and a new application must be submitted',
    link: '',
  },
  REJECTED_MATCH: {
    key: 'REJECTED_MATCH',
    value: 'REJECTED_MATCH',
    text: 'Match',
    message: 'This application has been declined for a positive hit on the MATCH report. MATCH is a system created and managed by Mastercard. It is a database that houses information about businesses (and their owners) whose credit card processing privileges have been terminated for a variety of reasons. Please reach out for additional details.',
    link: '',
  },
  REJECTED_INSUFFICIENT_FINANCIAL_BACKING: {
    key: 'REJECTED_INSUFFICIENT_FINANCIAL_BACKING',
    value: 'REJECTED_INSUFFICIENT_FINANCIAL_BACKING',
    text: 'Insufficient Financial Backing',
    message: 'This application has been declined due to insufficient financial backing for the business. Please reach out for additional details.',
    link: '',
  },
  REJECTED_FRAUD: {
    key: 'REJECTED_FRAUD',
    value: 'REJECTED_FRAUD',
    text: 'Fraud',
    message: 'This application has been declined due to suspected fraud. Please reach out for additional details.',
    link: '',
  },
  REJECTED_SIGNER_VERIFICATION: {
    key: 'REJECTED_SIGNER_VERIFICATION',
    value: 'REJECTED_SIGNER_VERIFICATION',
    text: 'Signer Verification',
    message: 'This application has been declined as we are unable to verify the signer(s) of the business. Please reach out for additional details.',
    link: '',
  },
  REJECTED_BUSINESS_VERIFICATION: {
    key: 'REJECTED_BUSINESS_VERIFICATION',
    value: 'REJECTED_BUSINESS_VERIFICATION',
    text: 'Business Verification',
    message: 'This application has been declined as we are unable to verify the existence of the business. Please reach out for additional details.',
    link: '',
  },
  REJECTED_PROHIBITED_PRODUCTS: {
    key: 'REJECTED_PROHIBITED_PRODUCTS',
    value: 'REJECTED_PROHIBITED_PRODUCTS',
    text: 'Prohibited Products',
    message: 'This application has been declined based on a prohibited product being offered. Please reach out for additional details and options for remedy.',
    link: '',
  },
  REJECTED_PROHIBITED_BUSINESS: {
    key: 'REJECTED_PROHIBITED_BUSINESS',
    value: 'REJECTED_PROHIBITED_BUSINESS',
    text: 'Prohibited Business',
    message: 'This application has been declined based on a prohibited business type. Please reach out for additional details.',
    link: '',
  },
  PENDED_SIGNER_VERIFICATION: {
    key: 'PENDED_SIGNER_VERIFICATION',
    value: 'PENDED_SIGNER_VERIFICATION',
    text: 'Signer Verification',
    message: 'Unable to verify one of more members of the organization. One of the following documents may be necessary: Drivers License, Gov Issued ID, Passport, Social Security Card.',
    link: '',
  },
  PENDED_VOLUME_CLARITY: {
    key: 'PENDED_VOLUME_CLARITY',
    value: 'PENDED_VOLUME_CLARITY',
    text: 'Volume Clarity',
    message: 'The requested volume parameters (annual, average or high dollar amounts) do not match the previous processing history provided or do not correlate with the merchant’s business type. Please reach out to discuss in more detail.',
    link: '',
  },
  PENDED_TIN_MISMATCH: {
    key: 'PENDED_TIN_MISMATCH',
    value: 'PENDED_TIN_MISMATCH',
    text: 'TIN Mismatch',
    message: 'The business tax identification number provided is unable to be verified. Please provide a current copy of your federally issued SS4 document or 147C letter from the IRS.',
    link: '',
  },
  PENDED_PREVIOUS_PROCESSING_HISTORY_CREDIT: {
    key: 'PENDED_PREVIOUS_PROCESSING_HISTORY_CREDIT',
    value: 'PENDED_PREVIOUS_PROCESSING_HISTORY_CREDIT',
    text: 'Previous Processing History / Credit',
    message: 'Please provide 3 months of previous processing statements. This gives us a look into your processing history but also helps us identify annual volume, average and high tickets. We\'re specifically looking for a detailed breakdown, including disputes and refunds. If you do not have previous processing statements please contact us to discuss additional options.',
    link: '',
  },
  PENDED_BUSINESS_LOCATION: {
    key: 'PENDED_BUSINESS_LOCATION',
    value: 'PENDED_BUSINESS_LOCATION',
    text: 'Business Location',
    message: 'Unable to verify the location of the business to the address provided on the application. Please provide business license, utility bill, or executed lease agreement.',
    link: '',
  },
  PENDED_MARKETING_MATERIAL: {
    key: 'PENDED_MARKETING_MATERIAL',
    value: 'PENDED_MARKETING_MATERIAL',
    text: 'Marketing Material',
    message: 'To understand what the business is taking payments for, we would like to see some marketing material. If you don\'t have a website, we can accept any documentation you use to advertise to your customers. For example, catalogs, brochures or items showing services/products provided including the pricing.',
    link: '',
  },
  PENDED_DEPOSIT_POLICY: {
    key: 'PENDED_DEPOSIT_POLICY',
    value: 'PENDED_DEPOSIT_POLICY',
    text: 'Deposit Policy',
    message: 'Please confirm the following: Is merchant taking payments in advance of services? If so, please answer the following questions: What percent of total payment is the merchant taking in advance? How long before services are fully rendered?',
    link: '',
  },
  PENDED_FINANCIALS_BANKING: {
    key: 'PENDED_FINANCIALS_BANKING',
    value: 'PENDED_FINANCIALS_BANKING',
    text: 'Financials/Banking',
    message: 'Please provide 3 months of current business bank statements. This gives us insight into the current financial standing of the business and its ability to support the requested ticket parameters.',
    link: '',
  },
  PENDED_BANKING_VERIFICATION: {
    key: 'PENDED_BANKING_VERIFICATION',
    value: 'PENDED_BANKING_VERIFICATION',
    text: 'Banking Verification',
    message: 'Unable to verify bank details. Please provide a copy of a business void check or a signed authorized bank letter. If a bank letter, please include: On bank Letterhead and signed by a bank representative, business name, full routing and account numbers.',
    link: '',
  },
  APPROVED_OTHER: {
    key: 'APPROVED_OTHER',
    value: 'APPROVED_OTHER',
    text: 'Other',
    message: 'Other',
    link: '',
  },
  APPROVED_WEBSITE_COMPLIANCE: {
    key: 'APPROVED_WEBSITE_COMPLIANCE',
    value: 'APPROVED_WEBSITE_COMPLIANCE',
    text: 'Website Compliance',
    message: 'Merchant can process payments and payments will not fund until website compliance has been resolved',
    link: '',
  },
  APPROVED_BANKING_VERIFICATION: {
    key: 'APPROVED_BANKING_VERIFICATION',
    value: 'APPROVED_BANKING_VERIFICATION',
    text: 'Banking Verification',
    message: 'Merchant can process payments and payments will not fund until banking details have been resolved',
    link: staxSignupUrl,
  },
  APPROVED_SIGNER_VERIFICATION: {
    key: 'APPROVED_SIGNER_VERIFICATION',
    value: 'APPROVED_SIGNER_VERIFICATION',
    text: 'Signer Verification',
    message: 'Merchant can process payments and payments will not fund until banking details have been resolved',
    link: '',
  },
})

function getUnderwritingSubStatusEnum(predicate, predicateKey = 'key') {
  const result = find(underwritingSubStatusEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

export default underwritingSubStatusEnum

export {
  getUnderwritingSubStatusEnum,
}
