<template>
  <div class="card card-statistics">
    <div class="card-body">
      <h5 class="card-title text-warning mb-1">
        Charge
      </h5>
      <p class="card-text">
        Charge Credit Card
      </p>
      <a class="btn btn-lg btn-primary bg-primary bg-lighten-3 py-1 d-flex flex-row justify-content-center" @click="createForm()">
        <p-icon size="36px" name="bi-credit-card-fill" class="align-self-center mr-2" />
        <span class="align-self-center">
          Charge
        </span>
      </a>
    </div>
    <div>
      <dx-util-popup
        ref="popupPayRef"
        :show-close-button="true"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-title="true"
        :full-screen="false"
        :title="creditPayPopupTitle"
        width="48vw"
        height="64vh"
        content-template="popup-content"
        @shown="onShownPayPopup"
      >
        <dx-util-position at="center" my="center" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
        <template #popup-content>
          <dx-util-scroll-view width="100%" height="100%">
            <form onsubmit="return false;">
              <dx-util-form
                ref="tokenizeFormRef"
                :form-data="tokenizeFormData"
                :col-count="1"
                :show-colon-after-label="true"
                label-mode="floating"
                label-location="left"
              >
                <dx-util-simple-item data-field="total" editor-type="dxNumberBox" :col-span="1">
                  <dx-util-required-rule message="Total Amount is required" />
                </dx-util-simple-item>
                <dx-util-group-item :col-count="1" caption="Address" css-class="border rounded px-1 mt-1">
                  <dx-util-simple-item data-field="address1" :label="{ text: 'Line 1' }">
                    <dx-util-required-rule message="Address is required" />
                  </dx-util-simple-item>
                  <dx-util-simple-item data-field="address2" :label="{ text: 'Line 2' }" />
                  <dx-util-group-item :col-count="4">
                    <dx-util-simple-item data-field="addressCity" :label="{ text: 'City' }">
                        <dx-util-required-rule message="City is required" />
                    </dx-util-simple-item>
                    <dx-util-simple-item data-field="addressState" editor-type="dxSelectBox" :editor-options="stateOptions" :label="{ text: 'State'}" />
                    <dx-util-simple-item data-field="addressZip" :label="{ text: 'Zip Code' }">
                      <dx-util-required-rule message="Zip Code is required" />
                    </dx-util-simple-item>
                    <dx-util-simple-item data-field="addressCountry" editor-type="dxSelectBox" :editor-options="countryOptions" :label="{ text: 'Country'}" />
                  </dx-util-group-item>
                </dx-util-group-item>
                <dx-util-group-item :col-count="4" caption="Card Info" css-class="border rounded px-1 mt-1">
                  <dx-util-simple-item data-field="firstName" :col-span="2">
                    <dx-util-required-rule message="First Name is required" />
                  </dx-util-simple-item>
                  <dx-util-simple-item data-field="lastName" :col-span="2">
                    <dx-util-required-rule message="Last Name is required" />
                  </dx-util-simple-item>
                  <dx-util-simple-item data-field="phone" :editor-options="phoneOptions" :col-span="2">
                    <dx-util-required-rule message="Phone Number is required" />
                  </dx-util-simple-item>
                  <dx-util-simple-item data-field="email" :col-span="2" />
                  <dx-util-simple-item :col-span="3">
                    <template #default>
                      <div>
                        <label class="form-label" for="card-number">Credit Card Number</label>
                        <div id="card-number" class="form-control" />
                      </div>
                    </template>
                  </dx-util-simple-item>
                  <dx-util-simple-item :col-span="1">
                    <template #default>
                      <div>
                        <label class="form-label" for="card-number">CVV</label>
                        <div id="card-cvv" class="form-control" />
                      </div>
                    </template>
                  </dx-util-simple-item>
                  <dx-util-simple-item data-field="month" :editor-options="mountOptions" :col-span="2">
                    <dx-util-required-rule message="Month is required" />
                  </dx-util-simple-item>
                  <dx-util-simple-item data-field="year" :editor-options="yearOptions" :col-span="2">
                    <dx-util-required-rule message="Year is required" />
                  </dx-util-simple-item>
                </dx-util-group-item>
              </dx-util-form>
            </form>
          </dx-util-scroll-view>
        </template>
      </dx-util-popup>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import useCurrentUser from '@/libs/app/current-user'
import staxService from '@/http/requests/external/stax'
import { Notify } from '@robustshell/utils/index'
import { countriesDetail, states } from '@/http/requests/statics/locations'

export default {
  setup() {
    const {
      isServingTenant,
      isIndividualTenant,
      isCompanyTypeWarehouse,
      userTenantId,
      userTenantType,
    } = useCurrentUser()
    return {
      isCompanyTypeWarehouse,
      isServingTenant,
      isIndividualTenant,
      userTenantId,
      userTenantType,
    }
  },

  data() {
    return {
      staxIns: null,
      creditPayPopupTitle: '',
      token: '',
      companyId: null,
      stores: [],
      responseData: null,
      completePay: true,
      tokenizeFormData: {
        total: null,
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        month: null,
        year: null,
        phone: '',
        address1: '',
        address2: '',
        addressCity: '',
        addressState: '',
        addressCountry: '',
        addressZip: '',
        method: 'card',
        validate: false,
      },
    }
  },
  computed: {
    formIns() {
      const form = this.$refs.tokenizeFormRef.instance
      return form
    },
    popupIns() {
      const popup = this.$refs.popupPayRef.instance
      return popup
    },
    mountOptions() {
      return {
        mask: '00',
      }
    },
    yearOptions() {
      return {
        mask: '0000',
      }
    },
    phoneOptions() {
      return {
        mask: '0000000000',
      }
    },
    storeOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: false,
        items: this.stores,
        acceptCustomValue: true,
        valueExpr: 'id',
        displayExpr: 'name',
      }
    },
    stateOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: states,
        acceptCustomValue: true,
      }
    },
    countryOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: countriesDetail,
        valueExpr: 'code',
        displayExpr: 'name',
      }
    },
    saveButtonOptions() {
      return {
        text: 'Charge',
        type: 'success',
        useSubmitBehavior: true,
        disabled: this.completePay,
        onClick: e => {
          this.handlePay(e)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.closePayPopup()
        },
      }
    },
  },
  created() {
  },
  methods: {
    async onShownPayPopup() {
      this.creditPayPopupTitle = 'Paying with a Card'
      const isPshCustomer = (this.isServingTenant && this.isCompanyTypeWarehouse) || this.isIndividualTenant
      try {
        const getToken = await staxService.getTenantWebsitePaymentsToken(isPshCustomer, this.userTenantId)
        this.token = getToken
        this.$nextTick(async () => {
          const staxJs = new StaxJs(this.token, {
            number: {
              id: 'card-number',
              placeholder: '0000 0000 0000 0000',
              type: 'text',
              format: 'prettyFormat',
            },
            cvv: {
              id: 'card-cvv',
              placeholder: 'CVV',
              type: 'text',
            },
          })
          const result = await staxJs.showCardForm()

          staxJs.on('card_form_complete', message => {
            const { validCvv, validNumber } = message
            this.completePay = !(validCvv && validNumber)
          })

          staxJs.on('card_form_incomplete', () => {
            this.completePay = true
          })

          this.staxIns = staxJs
        })
      } catch (err) {
        this.popupIns.hide()
      }
    },
    createForm() {
      this.popupIns.show()
    },
    closePayPopup() {
      this.popupIns.hide()
    },
    async handlePay() {
      const staxJs = this.staxIns
      try {
        const formValidate = this.formIns.validate()
        if (this.tokenizeFormData.addressCountry !== 'USA') {
          this.tokenizeFormData.addressState = 'NA'
        } else if (!this.tokenizeFormData.addressState) {
          Notify.warning('Address State is required. Please select address state.')
          return
        }
        if (formValidate.isValid) {
          const extraDetails = {
            total: this.tokenizeFormData.total,
            firstname: this.tokenizeFormData.firstName,
            lastname: this.tokenizeFormData.lastName,
            email: this.tokenizeFormData.email,
            company: this.tokenizeFormData.company,
            month: this.tokenizeFormData.month,
            year: this.tokenizeFormData.year,
            phone: this.tokenizeFormData.phone,
            address_1: this.tokenizeFormData.address1,
            address_2: this.tokenizeFormData.address2,
            address_city: this.tokenizeFormData.addressCity,
            address_state: this.tokenizeFormData.addressState,
            address_zip: this.tokenizeFormData.addressZip,
            address_country: this.tokenizeFormData.addressCountry,
            method: this.tokenizeFormData.method,
            validate: this.tokenizeFormData.validate,
          }
          const response = await staxJs.pay(extraDetails)
          if (response.id) {
            Notify.success('Payment has been complated successfully')
          }
          this.popupIns.hide()
        }
      } catch (err) {
        const messeage = err.payment_attempt_message || err
        Notify.error(messeage)
        this.popupIns.hide()
      }
    },
  },
}
</script>

<style>

</style>
