<template>
      <dx-data-grid
        id="childTransactionGrid"
        ref="childTransactionGridRef"
        :data-source="dataSource"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :row-alternation-enabled="false"
        :hover-state-enabled="true"
        :customize-columns="customizeColumns"
        @initialized="onInitialized"
        @content-ready="onContentReady"
      >
      <dx-column data-field="type" caption="Type" cell-template="successCellTemplate" />
        <template #successCellTemplate="{ data }">
          <div>
            <span v-if="data.value === 'refund'" class="badge badge-warning">Refunded</span>
            <span v-if="data.value === 'void'" class="badge badge-danger">Voided</span>
          </div>
        </template>
        <dx-column data-field="total" :format="{ type: 'currency', precision: 2 }" />
        <dx-column data-field="created_at" caption="Created At" width="100%" />
      </dx-data-grid>
</template>
<script>
/* eslint-disable no-unused-vars */
import GridBase from '@core/dev-extreme/mixins/grid/base'

export default {
  mixins: [GridBase],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const items = this.items
    return {
      dataSource: items,
    }
  },
  methods: {
    getChildTransactions(batchId) {
    },
  },

}
</script>
<style lang="scss">
</style>
