const underwritingStatusEnum = Object.freeze({
  INIT: { key: 'INIT', value: 'INIT', text: 'init' },
  INITIATED: { key: 'INITIATED', value: 'INITIATED', text: 'Initiated' },
  SUBMITTED: { key: 'SUBMITTED', value: 'SUBMITTED', text: 'Submitted' },
  PENDED: { key: 'PENDED', value: 'PENDED', text: 'Pended' },
  APPROVED: { key: 'APPROVED', value: 'APPROVED', text: 'Approved' },
  REJECTED: { key: 'REJECTED', value: 'REJECTED', text: 'Rejected' },
})
const merchantAcountStatusEnum = Object.freeze({
  ACTIVE: { key: 'ACTIVE', value: 'ACTIVE', text: 'Active' },
  PENDING: { key: 'PENDING', value: 'PENDING', text: 'Pending' },
  INACTIVE: { key: 'INACTIVE', value: 'INACTIVE', text: 'Inactive' },
})

const refundPolicyEnum = Object.freeze({
  N: { key: 'N', value: 'N', text: 'No Refunds' },
  E: { key: 'E', value: 'E', text: 'Merchandise Exchange Only' },
  T: { key: 'T', value: 'T', text: '30 Days or Fewer' },
  O: { key: 'O', value: 'O', text: 'Other' },
})

function getRefundPolicyEnumList() {
  const options = []
  Object.keys(refundPolicyEnum).map(key => options.push({ value: refundPolicyEnum[key].value, text: refundPolicyEnum[key].text }))
  return options
}

const companyTypeEnum = Object.freeze({
  A: { key: 'A', value: 'A', text: 'Association/Estate/Trust' },
  G: { key: 'G', value: 'G', text: 'Government (Federal/State/Local)' },
  S: { key: 'S', value: 'S', text: 'Individual/Sole Proprietor' },
  F: { key: 'F', value: 'F', text: 'Financial Institution' },
  L: { key: 'L', value: 'L', text: 'LLC (Limited-Liability Corporation)' },
  E: { key: 'E', value: 'E', text: 'Tax-Exempt Organization (501C)' },
  P: { key: 'P', value: 'P', text: 'Partnership' },
  V: { key: 'V', value: 'V', text: 'Private Corporation' },
  B: { key: 'B', value: 'B', text: 'Public Corporation' },
  R: { key: 'R', value: 'R', text: 'SEC-Registered Entity' },
})

function getCompanyTypeEnumList() {
  const options = []
  Object.keys(companyTypeEnum).map(key => options.push({ value: companyTypeEnum[key].value, text: companyTypeEnum[key].text }))
  return options
}

export {
  underwritingStatusEnum,
  merchantAcountStatusEnum,
  getRefundPolicyEnumList,
  getCompanyTypeEnumList,
}
