<template>
  <app-timeline>
    <app-timeline-item
      v-for="(item, index) in data"
      :key="index"
      :title="getMessage(item.key).text"
      :subtitle="getMessage(item.key).message"
      icon="AlertCircleIcon"
      time=""
      variant="danger"
    />
  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import underwritingSubStatusEnum, { getUnderwritingSubStatusEnum } from '@/enums/underwriting-substatus.enum'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      underwritingSubStatusEnum: underwritingSubStatusEnum,
    }
  },
  methods: {
    getMessage(key) {
      return getUnderwritingSubStatusEnum(key)
    },
  },
}
</script>
