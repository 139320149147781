import find from 'lodash/find'

/**
 * Enum for Merchant Team Member Role.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const teamMemberRoleEnum = Object.freeze({
  ADMIN: { key: 'admin', value: 'admin', text: 'Team Admin' },
  FULL: { key: 'full', value: 'full', text: 'Full Access' },
  REPORTING: { key: 'reporting', value: 'reporting', text: 'Reporting' },
  SALES_STAFF: { key: 'sales-staff', value: 'sales-staff', text: 'Staff' },
  STAFF: { key: 'staff', value: 'staff', text: 'Staff with Refunds' },
  SALES_ONLY: { key: 'sales-only', value: 'sales-only', text: 'Sales Only' },
  SALES_WITH_REFUND: { key: 'sales-with-refund', value: 'sales-with-refund', text: 'Sales with Refunds' },
})

function getTeamMemberRoleEnum(predicate, predicateKey = 'key') {
  const result = find(teamMemberRoleEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getListTeamMemberRoleEnum() {
  const result = Object.keys(teamMemberRoleEnum).map(key => teamMemberRoleEnum[key])
  return result
}

export default teamMemberRoleEnum

export {
  getTeamMemberRoleEnum,
  getListTeamMemberRoleEnum,
}
