/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import staxService from '@/http/requests/external/stax'
import Pager from '@core/dev-extreme/utils/pager'
import { v4 as uuidv4 } from 'uuid'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'id', desc: false }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortOrder(sort)

    const pageableQuery = `${pager.toCreatePageable()}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const response = await staxService.getDeposits(pageableQuery)
    return {
      data: response.data,
    }
  },
})

const depositsDataSource = new DataSource({
  store: store,
  map: dataItem => {
    const item = { ...dataItem }
    item.id = uuidv4()
    return item
  },
})

export {
  depositsDataSource as DepositsDataSource,
}
