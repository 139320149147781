<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <div class="row match-height">
          <div class="col-6 col-lg-2">
            <div class="card card-statistics">
              <div class="card-body">
                <h5 class="card-title text-warning mb-1">
                  Merchant Account Login
                </h5>
                <p class="card-text">
                  Access Merchant Account
                </p>
                <button class="btn btn-lg btn-block btn-success bg-success bg-lighten-3 d-flex flex-row justify-content-center" target="blank" @click="onClickContinueSignupProcess()">
                  <p-icon size="32px" name="bi-box-arrow-in-right" class="align-self-center mr-2" />
                  <span class="align-self-center">
                    Login
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3">
            <payment />
          </div>
          <div class="col-6 col-lg-3">
            <team-member />
          </div>
          <div v-if="accountStatus !== 'ACTIVE'" class="col-6 col-lg-4">
            <div class="card card-statistics">
              <div class="card-body">
                <h5 class="card-title text-warning mb-1">
                  Information
                </h5>
                <p class="card-text">
                  {{ underwritingNote }}
                </p>
                <p v-for="(item, index) in underwritingSubstatuses" :key="index" class="card-text text-warning">
                  <p-icon icon="bi-dot" color="warning" />
                  <a v-if="getLinkWithEphemeralToken(item.key)" :href="getLinkWithEphemeralToken(item.key)" target="_blank">{{ getMessage(item.key).message }}</a>
                  <span v-else>{{ getMessage(item.key).message }}</span>
                </p>
              </div>
            </div>
          </div>
          <div v-else class="col-6 col-lg-4">
            <disputes-card :is-merchant-connect="true" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-8 col-lg-8">
        <div class="card card-psh">
          <div class="bg-light-primary rounded-top p-1">
            <div class="psh-header mb-0 d-flex align-items-center px-2">
              <div class="psh-title border-0">
                <p-icon name="bi-credit-card" size="48px" color="primary" />
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  Recent Transactions
                </h4>
                <p class="card-text mb-0">
                  Transactions list in last 30 days
                </p>
              </div>
              <div class="ml-auto">
                <dx-util-button
                  type="default"
                  icon="pulldown"
                  @click="onClickReloadTransactions"
                />
              </div>
            </div>
          </div>
          <div class="card-body">
            <dx-util-scroll-view show-scrollbar="onHover" :height="scroolHeight">
              <dx-data-grid
                id="transactionGrid"
                ref="transactionGridRef"
                :data-source="TransactionDataSource"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :show-borders="true"
                :row-alternation-enabled="false"
                :hover-state-enabled="true"
                :customize-columns="customizeColumns"
                @option-changed="onOptionChanged"
                @initialized="onInitialized"
                @content-ready="onContentReady"
              >
                <dx-toolbar>
                  <dx-item location="before" template="startDateTemplate" />
                  <dx-item location="before" template="endDateTemplate" />
                  <dx-item location="before" template="typeTemplate" />
                </dx-toolbar>
                <template #startDateTemplate>
                  <dx-util-date-box
                    id="startDateDateBox"
                    v-model="transactionFilter.startDate"
                    placeholder="Start Date"
                    :max="transactionFilter.endDate"
                    type="date"
                    date-serialization-format="yyyy-MM-dd hh:mm:ss"
                    @value-changed="getTransactionData"
                  />
                </template>
                <template #endDateTemplate>
                  <dx-util-date-box
                    id="endDateDateBox"
                    v-model="transactionFilter.endDate"
                    placeholder="End Date"
                    :min="transactionFilter.startDate"
                    type="date"
                    date-serialization-format="yyyy-MM-dd hh:mm:ss"
                    @value-changed="getTransactionData"
                  />
                </template>
                <template #typeTemplate>
                  <dx-util-select-box
                    id="typeSelectBox"
                    v-model="transactionFilter.type"
                    :data-source="transactionTypes"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="getTransactionData"
                  />
                </template>
                <dx-sorting mode="single" />
                <dx-remote-operations :paging="true" :sorting="true" :filtering="false" />
                <dx-paging :page-size="20" />
                <dx-pager
                  :allowed-page-sizes="[10, 20, 30]"
                  :show-info="true"
                  :show-navigation-buttons="true"
                  :show-page-size-selector="true"
                  :visible="true"
                />
                <dx-column data-field="payment_method.nickname" caption="Card Alias" :allow-sorting="false" />
                <dx-column data-field="meta.memo" caption="Invoice No" width="160" :allow-sorting="false" />
                <dx-column data-field="card_type" caption="Card Type" alignment="center" :allow-sorting="false" cell-template="cardTypeCellTemplate" width="100" />
                <template #cardTypeCellTemplate="{ data }">
                  <b-img height="32" :src="data.value" :title="data.value" />
                </template>
                <dx-column data-field="last_four" caption="Card Number" :allow-sorting="false" width="135" />
                <dx-column data-field="created_at" width="150" caption="Created At" />
                <dx-column data-field="total_refunded" caption="Refunded" :allow-sorting="false" :format="{ type: 'currency', precision: 2 }" width="90" />
                <dx-column data-field="total" :format="{ type: 'currency', precision: 2 }" width="100" />
                <dx-column data-field="message" width="100" />
                <dx-column data-field="type" caption="Type" :allow-sorting="false" alignment="center" cell-template="successCellTemplate" width="140" />
                <template #successCellTemplate="{ data }">
                  <span class="d-block badge" :class="getTransactionTypeFormated(data.data).variant">
                    {{ getTransactionTypeFormated(data.data).text }}
                  </span>
                </template>
                <dx-master-detail :enabled="true" template="masterDetailTemplate" />
                <template #masterDetailTemplate="{ data: items }">
                  <child-transaction :items="items.data.child_transactions" />
                </template>
              </dx-data-grid>
            </dx-util-scroll-view>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="card card-psh">
          <div class="bg-light-primary rounded-top p-1">
            <div class="psh-header mb-0 d-flex align-items-center px-2">
              <div class="psh-title border-0">
                <p-icon name="bi-building" size="48px" color="primary" />
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  Recent Deposits
                </h4>
                <p class="card-text mb-0">
                  Deposit list in last 30 days
                </p>
              </div>
              <div class="ml-auto">
                  <dx-util-button
                    type="default"
                    icon="pulldown"
                    @click="onClickReloadDeposits"
                  />
                </div>
            </div>
          </div>
          <div class="card-body">
            <dx-util-scroll-view show-scrollbar="onHover" :height="scroolHeight">
              <dx-data-grid
                id="depositsGrid"
                ref="depositsGridRef"
                :data-source="DepositsDataSource"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :show-borders="true"
                :row-alternation-enabled="false"
                :hover-state-enabled="true"
                :customize-columns="customizeColumns"
                @option-changed="onOptionChanged"
                @initialized="onInitialized"
                @content-ready="onContentReady"
              >
                <dx-column data-field="display_batch_id" caption="Batch ID" />
                <dx-column data-field="last_transaction" caption="Last Transaction Date" />
                <dx-column data-field="count" caption="Transaction Count" />
                <dx-column data-field="fees" :format="{ type: 'currency', precision: 2 }" />
                <dx-column data-field="sum" caption="Total" :format="{ type: 'currency', precision: 2 }" />
              </dx-data-grid>
            </dx-util-scroll-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DisputesCard from '@/views/apps/account/merchant-connect/components/DisputesCard.vue'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import moment from 'moment'
import staxService from '@/http/requests/external/stax'
import { getUnderwritingSubStatusEnum } from '@/enums/underwriting-substatus.enum'
import { TransactionDataSource } from './transaction.store'
import { DepositsDataSource } from './deposits.store'
import { DisputesDataSource } from './disputes.store'
import ChildTransaction from './ChildTransaction.vue'
import Payment from './Payment.vue'
import TeamMember from './TeamMember.vue'

const staxLoginUrl = process.env.VUE_APP_STAX_LOGIN_URL

export default {
  components: {
    ChildTransaction,
    Payment,
    TeamMember,
    DisputesCard,
  },
  mixins: [GridBase],
  props: {
    link: {
      type: String,
      required: true,
    },
    underwritingNote: {
      type: String,
      required: false,
      default: '',
    },
    underwritingSubstatuses: {
      type: Array,
      required: false,
      default: () => [],
    },
    accountStatus: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    const start = moment().add(-1, 'months').format('YYYY-MM-DD HH:mm:ss')
    const now = moment().add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
    return {
      TransactionDataSource,
      DepositsDataSource,
      DisputesDataSource,
      userToken: '',
      statisticsItems: [
        {
          icon: 'ActivityIcon',
          color: 'badge-light-success',
          title: 'Total Transactions',
          subtitle: '40',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'MinusCircleIcon',
          color: 'badge-light-danger',
          title: 'Failed Transctions',
          subtitle: '12',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'RotateCcwIcon',
          color: 'badge-light-primary',
          title: 'Refunded',
          subtitle: '6',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'AlertTriangleIcon',
          color: 'badge-light-warning',
          title: 'Voided',
          subtitle: '4',
          customClass: '',
        },
      ],
      transactionTypes: [
        { value: 'charge', text: 'Charge' },
        { value: 'refund', text: 'Refund' },
        { value: 'void', text: 'Void' },
      ],
      transactionFilter: {
        export: '',
        keywords: '',
        startDate: start,
        endDate: now,
        userId: '',
        customerId: '',
        meta: '',
        total: null,
        method: '',
        maxTotal: null,
        minTotal: null,
        type: 'charge',
        success: '',
        order: 'DESC',
        sort: 'created_at',
        per_page: 20,
      },
    }
  },
  computed: {
    scroolHeight() {
      return window.innerHeight - 550
    },
  },
  async mounted() {
    this.loadData()
    this.getUserEphemeralToken()
  },
  methods: {
    getUserData() {
      return JSON.parse(localStorage.getItem('userData'))
    },
    getMessage(key) {
      return getUnderwritingSubStatusEnum(key)
    },
    getLinkWithEphemeralToken(key) {
      const signupPageLink = this.getMessage(key)
      return signupPageLink.link ? signupPageLink.link + this.userToken : ''
    },
    async getUserEphemeralToken() {
      const userData = this.getUserData()
      const userId = userData.id
      const result = await staxService.userEphemeralToken(userId)
      if (result) {
        this.userToken = result.token
      }
    },
    getTransactionTypeFormated(transaction) {
      if (!transaction.success) return { variant: 'badge-danger', text: 'X Failed Charge' }
      if (transaction.is_voided) return { variant: 'badge-light-danger', text: 'Voided' }
      if (transaction.total_refunded > 0 && transaction.total_refunded < transaction.total) return { variant: 'badge-light-warning', text: 'Partially Refunded' }
      if (transaction.total_refunded === transaction.total) return { variant: 'badge-light-warning', text: 'Refunded' }
      if (transaction.total !== 0) return { variant: 'badge-success', text: 'Charge' }
      return { variant: 'badge-light-secondary', text: 'Unknown' }
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        TransactionDataSource.pageIndex(0)
        TransactionDataSource.reload()
      }
    },
    loadTransactions() {
      TransactionDataSource.searchValue(this.transactionFilter)
      TransactionDataSource.load()
    },
    loadData() {
      this.loadTransactions()
      DepositsDataSource.load()
      DisputesDataSource.load()
    },
    onClickReloadTransactions() {
      TransactionDataSource.reload()
    },
    onClickReloadDeposits() {
      DepositsDataSource.reload()
    },
    async onClickContinueSignupProcess(e) {
      const userData = this.getUserData()
      const userId = userData.id
      const result = await staxService.userEphemeralToken(userId)
      if (result) {
        const url = `${staxLoginUrl}${result.token}`
        window.open(url, '_blank')
      }
    },
    getTransactionData(e) {
      TransactionDataSource.searchValue(this.transactionFilter)
      TransactionDataSource.reload()
    },
  },
}
</script>

<style></style>
