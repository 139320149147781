<template>
  <div class="container-fluid">
    <div v-if="process === underwritingStatusEnum.INIT.value" class="row">
      <div class="col-md-12 col-lg-12 d-flex align-items-stretch">
        <div class="card card-developer-meetup flex-fill">
          <div class="bg-light-primary rounded-top p-1">
            <div class="meetup-header mb-0 d-flex align-items-center px-2">
              <div class="meetup-day border-0">
                <p-icon name="bi-people-fill" size="48px" color="primary" />
              </div>
              <div class="my-auto">
                <h4 class="card-title mb-25">
                  User Information
                </h4>
                <p class="card-text mb-0">
                  Enter owner information
                </p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- #region Merchant Info -->
            <dx-util-form
              ref="enrollRequestMerchanRef"
              :form-data="enrollRequest"
              :element-attr="{ autocomplete: 'off'}"
              :col-count="2"
              :show-colon-after-label="true"
              label-mode="floating"
              label-location="left"
            >
              <dx-util-group-item :col-count="2" caption="Owner Information">
                <dx-util-simple-item :col-span="2" data-field="registration.title" :label="{ text: 'Title'}" />
                <dx-util-simple-item data-field="registration.first_name" :label="{ text: 'First Name'}">
                  <dx-util-required-rule message="First Name is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="registration.last_name" :label="{ text: 'Last Name'}">
                  <dx-util-required-rule message="Last Name is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="registration.email" :label="{ text: 'Email'}">
                  <dx-util-required-rule message="Email is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="registration.business_location_phone_number" :editor-options="phoneNumberOptions" :label="{ text: 'Phone Number'}" />
                <dx-util-simple-item data-field="user.password" editor-type="dxTextBox" :editor-options="passwordOptions">
                  <dx-util-required-rule message="Password is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="user.password_confirmation" editor-type="dxTextBox" :editor-options="passwordOptions" />
              </dx-util-group-item>
              <dx-util-group-item :col-count="2" caption="Address">
                <dx-util-simple-item :col-span="2" data-field="registration.business_location_address_1" :label="{ text: 'Address 1'}">
                  <dx-util-required-rule message="Address Line 1 is required" />
                </dx-util-simple-item>
                <dx-util-simple-item :col-span="2" data-field="registration.business_location_address_2" :label="{ text: 'Address 2'}" />
                <dx-util-simple-item data-field="registration.business_location_address_city" :label="{text: 'City'}">
                  <dx-util-required-rule message="City is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="registration.business_location_address_state" editor-type="dxSelectBox" :editor-options="stateOptions" :label="{ text: 'State'}">
                  <dx-util-required-rule message="State is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="registration.business_location_address_zip" :label="{text: 'Zip Code'}">
                  <dx-util-required-rule message="Zip Code is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="registration.business_location_address_country" editor-type="dxSelectBox" :editor-options="countryOptions" :label="{ text: 'Country'}">
                  <dx-util-required-rule message="Country is required" />
                </dx-util-simple-item>
              </dx-util-group-item>
            </dx-util-form>
            <!-- #endregion Merchant Info -->
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card card-developer-meetup">
          <div class="bg-light-primary rounded-top p-1">
            <div class="meetup-header mb-0 d-flex align-items-center px-2">
              <div class="meetup-day border-0">
                <p-icon name="bi-building" size="48px" color="primary" />
              </div>
              <div class="my-auto">
                <h4 class="card-title mb-25">
                  Company Information
                </h4>
                <p class="card-text mb-0">
                  Enter business information
                </p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- #region User Info -->
            <dx-util-form ref="enrollRequestRegistrationRef"
              :form-data="enrollRequest"
              :element-attr="{ autocomplete: 'off'}"
              :col-count="2"
              label-location="left"
              label-mode="floating"
            >
              <dx-util-group-item :col-count="4" caption="Company Information">
                <dx-util-simple-item :col-span="4" data-field="registration.business_legal_name" :label="{ text: 'Company Name' }">
                  <dx-util-required-rule message="Company Name is required" />
                </dx-util-simple-item>
                <dx-util-simple-item :col-span="2" data-field="registration.business_open_date" editor-type="dxDateBox" :label="{ text: 'Open Date' }" />
                <dx-util-simple-item :col-span="2" data-field="registration.company_type" editor-type="dxSelectBox" :editor-options="companyTypeOptions" :label="{ text: 'Company Type'}" />
                <dx-util-simple-item :col-span="2" data-field="merchant.contact_name" :label="{ text: 'Contact Name' }">
                  <dx-util-required-rule message="Contact Name is required" />
                </dx-util-simple-item>
                <dx-util-simple-item :col-span="2" data-field="merchant.contact_email" :label="{ text: 'Company Email' }">
                  <dx-util-required-rule message="Contact Email is required" />
                  <dx-util-email-rule message="Email is invalid" />
                </dx-util-simple-item>
                <dx-util-simple-item :col-span="2" data-field="registration.business_fax" :editor-options="phoneNumberOptions" :label="{ text: 'Fax Number' }" />
                <dx-util-simple-item :col-span="2" data-field="merchant.contact_phone" :editor-options="phoneNumberOptions" :label="{ text: 'Phone Number' }">
                  <dx-util-required-rule message="Contact Phone is required" />
                </dx-util-simple-item>
                <dx-util-simple-item :col-span="4" data-field="merchant.address_1" :label="{ text: 'Address 1' }">
                  <dx-util-required-rule message="Address Line 1 is required" />
                </dx-util-simple-item>
                <dx-util-simple-item :col-span="4" data-field="merchant.address_2" :label="{ text: 'Address 2' }" />
                <dx-util-simple-item :col-span="2" data-field="merchant.address_city" :label="{ text: 'City' }">
                  <dx-util-required-rule message="City is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="merchant.address_state" editor-type="dxSelectBox" :editor-options="stateOptions" :label="{ text: 'State' }">
                  <dx-util-required-rule message="State is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="merchant.address_zip" :label="{ text: 'Zip Code' }">
                  <dx-util-required-rule message="Zip Code is required" />
                </dx-util-simple-item>
              </dx-util-group-item>
              <dx-util-group-item :col-count="2" caption="Business Information">
                <dx-util-simple-item :col-span="2" data-field="registration.service_you_provide" :label="{ text: 'Services Provided' }" />
                <dx-util-simple-item :col-span="2" data-field="registration.business_website" :label="{ text: 'Website' }" />
                <dx-util-simple-item data-field="registration.business_tax_id" :editor-options="taxIdOptions" :label="{ text: 'Tax ID' }">
                  <dx-util-required-rule message="Tax ID is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="registration.business_dba" :label="{ text: 'DBA' }">
                  <dx-util-required-rule message="Business DBA is required. Enter business name if there is none." />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="registration.annual_volume" :label="{ text: 'Annual Volume' }" />
                <dx-util-simple-item data-field="registration.highest_trans_amount" :label="{ text: 'Highest Transaction Amount' }" />
                <dx-util-simple-item data-field="registration.card_not_present_percent" :label="{ text: 'Card Not Present Percent' }" />
                <dx-util-simple-item data-field="registration.bank_routing_number" :label="{ text: 'Bank Routing Number' }">
                  <dx-util-string-length-rule :min="9" :max="9" message="Routing number must be 9 digits" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="registration.bank_account_owner_name" :label="{ text: 'Bank Account Owner Name' }" />
                <dx-util-simple-item data-field="registration.bank_account_number" :label="{ text: 'Bank Account Number' }" />
              </dx-util-group-item>
            </dx-util-form>
            <!-- #endregion Merchant Info -->
          </div>
          <div class="card-footer text-right">
            <dx-util-button
              icon="save"
              text="Submit Application"
              type="success"
              styling-mode="contained"
              width="250"
              @click="onClickSave($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="process === underwritingStatusEnum.INITIATED.value">
      <div class="d-flex justify-content-center align-items-center" style="height: 80vh !important">
        <div class="card w-50">
          <div class="card-body">
            <div class="d-flex row flex-row align-items-center">
              <div class="align-self-center col-lg-6 col-md-6 col-sm-12" style="height: 30vh !important">
                <div class="img-pending-status" :style="`background-image: url('${imgSrcPending}');`" />
              </div>
              <div class="align-self-center text-center col-lg-6 col-md-6 col-sm-12">
                <h3 class="text-warning">
                  Your application is not complete yet. Please click on the Continue Signup Process to complete your application.
                </h3>
                <a class="btn btn-lg btn-primary bg-primary bg-lighten-3 border-0 p-5 d-flex flex-column justify-content-center align-items-center" target="blank" :href="staxAppSignupUrl">
                  <p-icon size="36px" name="bi-box-arrow-in-right" />
                  <span>
                    Continue Signup Process
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="process === underwritingStatusEnum.SUBMITTED.value">
      <div class="d-flex justify-content-center align-items-center" style="height: 80vh !important">
        <div class="card w-50">
          <div class="card-body">
            <div class="d-flex row flex-row align-items-center">
              <div class="align-self-center col-lg-6 col-md-6 col-sm-12" style="height: 30vh !important">
                <div class="img-pending-status" :style="`background-image: url('${imgSrcPending}');`" />
              </div>
              <div class="align-self-center text-center col-lg-6 col-md-6 col-sm-12">
                <h3 class="text-warning">
                  You have successfully submitted your application. It is currently being reviewed. Please continue to monitor this page for further actions.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="process === underwritingStatusEnum.APPROVED.value">
      <div v-if="accountStatus === merchantAcountStatusEnum.ACTIVE.value">
          <merchant-connect-dashboard
            :link="staxAppLoginUrl"
            :underwriting-note="underwritingNote"
            :underwriting-substatuses="underwritingSubstatuses"
            :account-status="accountStatus"
          />
      </div>
      <div v-else>
        <div class="d-flex justify-content-center align-items-center" style="height: 80vh !important">
          <div class="card w-75">
            <div class="card-body">
              <div class="d-flex row flex-row align-items-center">
                <div class="align-self-center col-lg-4 col-md-4 col-sm-12" style="height: 30vh !important">
                  <div class="img-pending-status" :style="`background-image: url('${imgSrcActive}');`" />
                </div>
                <div v-if="accountStatus === merchantAcountStatusEnum.INACTIVE.value" class="align-self-center col-lg-8 col-md-8 col-sm-12">
                  <h3 class="px-1 mb-2 text-warning">
                    This account has been approved but not activated yet.
                  </h3>
                  <h5 class="px-1 mb-2">
                    Notes: {{ underwritingNote }}
                  </h5>
                  <the-status-info :data="underwritingSubstatuses" />
                </div>
                <div v-if="accountStatus === merchantAcountStatusEnum.PENDING.value" class="align-self-center col-lg-8 col-md-8 col-sm-12">
                  <h3 class="px-1 mb-2 text-warning">
                    This account has been approved but not activated yet.
                  </h3>
                  <h5 class="px-1 mb-2">
                    Notes: {{ underwritingNote }}
                  </h5>
                  <the-status-info :data="underwritingSubstatuses" />
                  <a class="btn btn-lg btn-block btn-primary bg-lighten-3 border-0 mt-1" target="blank" :href="staxAppSignupUrl">
                    <p-icon name="bi-box-arrow-in-right" size="26px" />
                    <span class="m-1">
                      Continue Signup Process
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="process === underwritingStatusEnum.PENDED.value">
      <div class="d-flex justify-content-center align-items-center" style="height: 80vh !important">
        <div class="card w-75">
          <div class="card-body">
            <div class="d-flex row flex-row align-items-center">
              <div class="align-self-center col-lg-4 col-md-4 col-sm-12" style="height: 30vh !important">
                <div class="img-pending-status" :style="`background-image: url('${imgSrcActive}');`" />
              </div>
              <div class="align-self-center col-lg-8 col-md-8 col-sm-12">
                <h3 class="px-1 mb-2 text-warning">
                  Account underwriting status is pended. Please check the following items and take necessarry actions.
                </h3>
                <h5 class="px-1 mb-1">
                  Notes: {{ underwritingNote }}
                </h5>
                <the-status-info :data="underwritingSubstatuses" />
                <a class="btn btn-lg btn-block btn-primary bg-lighten-3 border-0 mt-1" target="blank" :href="staxAppSignupUrl">
                  <p-icon size="26px" name="bi-box-arrow-in-right" />
                  <span class="m-1">
                    Continue Signup Process
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="process === underwritingStatusEnum.REJECTED.value">
      <div class="d-flex justify-content-center align-items-center" style="height: 80vh !important">
        <div class="card w-50">
          <div class="card-body">
            <div class="d-flex row flex-row align-items-center">
              <div class="align-self-center col-lg-4 col-md-4 col-sm-12" style="height: 30vh !important">
                <div class="img-pending-status" :style="`background-image: url('${imgSrcActive}');`" />
              </div>
              <div class="align-self-center col-lg-8 col-md-8 col-sm-12">
                <h3 class="px-1 mb-2 text-danger">
                  Your Merchant Connect application has been rejected. Please contact us for further details.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */

import staxService from '@/http/requests/external/stax'
import companyService from '@/http/requests/company/companyService'
import { Notify } from '@robustshell/utils/index'
import { countriesDetail, states } from '@/http/requests/statics/locations'
import { getCompanyTypeEnumList, underwritingStatusEnum, merchantAcountStatusEnum } from './stax.const'
import TheStatusInfo from './TheStatusInfo.vue'
import MerchantConnectDashboard from './components/MerchantConnectDashboard.vue'

const staxSignupUrl = process.env.VUE_APP_STAX_SIGNUP_URL
const staxLoginUrl = process.env.VUE_APP_STAX_LOGIN_URL

export default {
  components: {
    TheStatusInfo,
    MerchantConnectDashboard,
  },
  data() {
    return {
      imgSrcPending: require('@/assets/images/undraw/information.svg'),
      imgSrcActive: require('@/assets/images/undraw/login.svg'),
      process: null,
      accountStatus: null,
      underwritingStatusEnum: underwritingStatusEnum,
      merchantAcountStatusEnum: merchantAcountStatusEnum,
      userInfo: {},
      companyInfo: {},
      enrollRequest: {
        userId: null,
        companyId: null,
        skip_account_page: true,
        merchant: {
          plan: 'premium', // Not added form
          company_name: '',
          contact_name: '',
          contact_email: '',
          contact_phone: '',
          address_1: '',
          address_2: '',
          address_city: '',
          address_state: '',
          address_zip: '',
          hosted_payments_token: '', // Not added form
          status: 'PENDING', // Not added form
        },
        registration: {
          pricing_plan: 'test-pricing', // Not added form
          underwriting_status: null, // Not added form
          first_name: '',
          last_name: '',
          business_location_phone_number: '',
          email: '',
          title: '',
          job_title: '',
          company_type: 'A', // -
          entity_ownership_type: 'PRIVATE', // Not added form
          business_legal_name: '', // companyInfo => company_name
          business_dba: '', // -
          business_website: '', // -
          business_fax: '', // -
          business_email: '', // companyInfo => contact_email
          business_location_address_1: '',
          business_location_address_2: '',
          business_location_address_city: '',
          business_location_address_state: '',
          business_location_address_zip: '',
          business_location_address_country: '',
          business_tax_id: '', // -
          business_open_date: '', // -
          service_you_provide: '',
          annual_volume: '',
          highest_trans_amount: '',
          card_not_present_percent: '',
          bank_routing_number: '',
          bank_account_number: '',
          bank_account_owner_name: '',
          secondary_bank_routing_number: '',
          secondary_bank_account_number: '',
          secondary_bank_account_purpose: '',
          principal_owners_name: '',
          chosen_processing_method: 'api', // Not added form
        },
        user: {
          name: '',
          email: '',
          team_role: 'admin', // Not added form
          team_enabled: true, // Not added form
          password: '',
          password_confirmation: '',
        },
      },
      staxAppSignupUrl: staxSignupUrl,
      staxAppLoginUrl: staxLoginUrl,
      underwritingSubstatuses: [],
      underwritingNote: '',
      phoneRules: {
        X: /[02-9]/,
      },
      taxIdRules: {
        X: /[02-9]/,
      },
    }
  },
  computed: {
    passwordComparison() {
      return this.enrollRequest.user.password
    },
    passwordOptions() {
      return {
        mode: 'password',
      }
    },
    phoneNumberOptions() {
      return { maskRules: this.phoneRules, mask: '(X00) 000-0000' }
    },
    taxIdOptions() {
      return { maskRules: this.taxIdRules, mask: '00-0000000' }
    },
    companyTypeOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: false,
        items: [...getCompanyTypeEnumList()],
        acceptCustomValue: false,
        valueExpr: 'value',
        displayExpr: 'text',
      }
    },
    countryOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: countriesDetail,
        label: 'Country',
        valueExpr: 'code',
        displayExpr: 'name',
      }
    },
    stateOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: states,
        acceptCustomValue: true,
        label: 'State',
      }
    },
  },
  mounted() {
    this.getMerchantInfo()
  },
  methods: {
    getUserData() {
      return JSON.parse(localStorage.getItem('userData'))
    },
    async getMerchantInfo() {
      this.underwritingSubstatuses.length = 0
      const userData = this.getUserData()
      this.enrollRequest.userId = userData.id
      this.enrollRequest.companyId = userData.companyId
      const staxResult = await staxService.getMerchantRegistrationData(userData.companyId)
      if (staxResult) {
        if (staxResult.underwriting_substatuses) {
          this.underwritingSubstatuses = [...staxResult.underwriting_substatuses]
        }
        this.underwritingNote = staxResult.underwriting_note
        if (!staxResult.underwriting_status) {
          if (!staxResult.electronic_signature || !staxResult.electronic_signature.timestamp) {
            // Merchant initiated the application but has not submitted yet
            const userEphemeralToken = await this.getUserEphemeralToken(userData.id)
            if (userEphemeralToken) {
              this.staxAppSignupUrl = `${this.staxAppSignupUrl}${userEphemeralToken.token}`
              this.process = underwritingStatusEnum.INITIATED.value
            }
          } else {
            this.process = underwritingStatusEnum.SUBMITTED.value
          }
          return
        }
        if (staxResult.underwriting_status === underwritingStatusEnum.APPROVED.value) {
          let accountStatus = null
          if (staxResult.merchant.status === merchantAcountStatusEnum.ACTIVE.value) {
            const userEphemeralToken = await this.getUserEphemeralToken(userData.id)
            if (userEphemeralToken.token) {
              this.staxAppLoginUrl = `${this.staxAppLoginUrl}${userEphemeralToken.token}`
              accountStatus = merchantAcountStatusEnum.ACTIVE.value
            }
            this.accountStatus = accountStatus
          } else if (staxResult.merchant.status === merchantAcountStatusEnum.INACTIVE.value) {
            accountStatus = merchantAcountStatusEnum.INACTIVE.value
          } else if (staxResult.merchant.status === merchantAcountStatusEnum.PENDING.value) {
            accountStatus = merchantAcountStatusEnum.PENDING.value
            const userEphemeralToken = await this.getUserEphemeralToken(userData.id)
            if (userEphemeralToken) {
              this.staxAppSignupUrl = `${this.staxAppSignupUrl}${userEphemeralToken.token}`
            }
          }
          this.accountStatus = accountStatus
          this.process = underwritingStatusEnum.APPROVED.value
          return
        }
        if (staxResult.underwriting_status === underwritingStatusEnum.REJECTED.value) {
          this.process = underwritingStatusEnum.REJECTED.value
          return
        }
        if (staxResult.underwriting_status === underwritingStatusEnum.PENDED.value) {
          this.process = underwritingStatusEnum.PENDED.value
          const userEphemeralToken = await this.getUserEphemeralToken(userData.id)
          if (userEphemeralToken) {
            this.staxAppSignupUrl = `${this.staxAppSignupUrl}${userEphemeralToken.token}`
          }
        }
      } else {
        this.process = underwritingStatusEnum.INIT.value
        const result = await companyService.getCompanyMerchantConnectInfo()
        this.setEnrollRequestMerchantInfo(result.merchant)
        this.setEnrollRequestUserInfo(result.user)
        this.setEnrollRegistrationInfo(result.registration)
      }
    },
    async getUserEphemeralToken(userId) {
      const result = await staxService.userEphemeralToken(userId)
      if (result) {
        return result
      }
      return null
    },
    setEnrollRequestMerchantInfo(data) {
      this.enrollRequest.merchant = {
        ...this.enrollRequest.merchant,
        plan: data.plan,
        company_name: data.company_name,
        contact_name: data.contact_name,
        contact_email: data.contact_email,
        contact_phone: data.contact_phone,
        address_1: data.address_1,
        address_2: data.address_2,
        address_city: data.address_city,
        address_state: data.address_state,
        address_zip: data.address_zip,
        hosted_payments_token: data.hosted_payments_token,
        status: data.status,
      }
    },
    setEnrollRequestUserInfo(data) {
      this.enrollRequest.user = {
        ...this.enrollRequest.user,
        name: data.name,
        email: data.email,
        team_role: data.team_role,
        team_enabled: data.team_enabled,
      }
    },
    setEnrollRegistrationInfo(data) {
      this.enrollRequest.registration = {
        ...this.enrollRequest.registration,
        annual_volume: data.annual_volume,
        bank_account_number: data.bank_account_number,
        bank_account_owner_name: data.bank_account_owner_name,
        bank_routing_number: data.bank_routing_number,
        business_dba: data.business_legal_name,
        business_email: data.business_email,
        business_fax: data.business_fax,
        business_legal_name: data.business_legal_name,
        business_open_date: data.business_open_date,
        business_tax_id: data.business_tax_id ? data.business_tax_id.replace(/[^\d]/g, '') : '',
        business_website: data.business_website,
        card_not_present_percent: data.card_not_present_percent,
        chosen_processing_method: data.chosen_processing_method,
        company_type: data.company_type,
        email: data.email,
        entity_ownership_type: data.entity_ownership_type,
        first_name: data.first_name,
        highest_trans_amount: data.highest_trans_amount,
        job_title: data.job_title,
        last_name: data.last_name,
        business_location_address_1: data.business_location_address_1,
        business_location_address_2: data.business_location_address_2,
        business_location_address_city: data.business_location_address_city,
        business_location_address_country: data.business_location_address_country,
        business_location_address_state: data.business_location_address_state,
        business_location_address_zip: data.business_location_address_zip,
        business_location_phone_number: data.business_location_phone_number,
        pricing_plan: data.pricing_plan,
        principal_owners_name: data.principal_owners_name,
        secondary_bank_account_number: data.secondary_bank_account_number,
        secondary_bank_account_purpose: data.secondary_bank_account_purpose,
        secondary_bank_routing_number: data.secondary_bank_routing_number,
        service_you_provide: data.service_you_provide,
        title: data.title,
        underwriting_status: data.underwriting_status,
      }
    },
    async onClickSave(e) {
      const formMerchant = this.$refs.enrollRequestMerchanRef.instance
      const formMerchantValidate = formMerchant.validate()
      const formRegistration = this.$refs.enrollRequestRegistrationRef.instance
      const formRegistrationValidate = formRegistration.validate()
      if (formMerchantValidate && formRegistrationValidate) {
        const data = this.enrollRequest

        if (data.user.password !== data.user.password_confirmation) {
          Notify.error('Password does not match. Please make sure you entered same password in password confirmation')
          return
        }
        data.user.email = data.registration.email
        data.user.name = `${data.registration.first_name} ${data.registration.last_name}`

        data.registration.job_title = data.registration.title
        data.registration.business_email = data.merchant.contact_email
        data.registration.principal_owners_name = data.registration.bank_account_owner_name
        data.registration.owner_address_1 = data.registration.business_location_address_1
        data.registration.owner_address_2 = data.registration.business_location_address_2
        data.registration.owner_address_city = data.registration.business_location_address_city
        data.registration.owner_address_state = data.registration.business_location_address_state
        data.registration.owner_address_zip = data.registration.business_location_address_zip
        data.registration.owner_address_country = data.registration.business_location_address_country
        data.registration.phone_number = data.registration.business_location_phone_number
        data.registration.business_location_phone_number = data.registration.business_location_phone_number.replace(/[^\d]/g, '')
        data.registration.business_fax = data.registration.business_fax.replace(/[^\d]/g, '')
        data.registration.business_tax_id = data.registration.business_tax_id.replace(/[^\d]/g, '')

        data.merchant.company_name = data.registration.business_legal_name
        data.merchant.contact_name = `${data.registration.first_name} ${data.registration.last_name}`
        data.merchant.contact_phone = data.merchant.contact_phone.replace(/[^\d]/g, '')
        const result = await staxService.enrollMerchant(data)
        if (result) {
          this.staxAppSignupUrl = `${this.staxAppSignupUrl}${result.token}`
          this.process = underwritingStatusEnum.INITIATED.value
        }
      }
    },
  },
}
</script>

<style lang="scss">
  .img-pending-status {
    height: 30vh !important;
    width: 100% !important;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  }
</style>
